export default function InitializePendo(
	orgName: string,
	displayName: string,
	apiKey: string,
	environmentName: string
) {
	try {
		(function () {
			(function (p: any, e: any, n: string, d: string, o: any) {
				let w: number;
				let x: number;
				o = p[d] = p[d] || {};
				o._q = o._q || [];
				const v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
				for (w = 0, x = v.length; w < x; ++w)
					((m) => {
						o[m] =
							o[m] ||
							function (...innerArgs: any[]) {
								o._q[m === v[0] ? "unshift" : "push"]([m, ...innerArgs]);
							};
					})(v[w]);
				const y = e.createElement(n);
				y.async = true;
				y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
				const z = e.getElementsByTagName(n)[0];
				z.parentNode.insertBefore(y, z);
			})(window, document, "script", "pendo", (window as any).pendo || {});

			const pendo_visitorId = orgName;
			const pendo_accountId = orgName; // TODO: Replace with the actual org name
			const pendo_accountName = displayName; // TODO: Replace with the actual org display name
			const pendo_environment = environmentName; // TODO: Read this key value from the Environment variable (Configure new one if required)
			const pendo_application = "Speakfully";

			(window as any).pendo.initialize({
				visitor: {
					id: pendo_visitorId,
				},
				account: {
					id: pendo_accountId,
					name: pendo_accountName,
					environment: pendo_environment,
					application: pendo_application,
				},
				disableGuides: false,
			});
		})();
	} catch (error) {
		console.error(error);
	}
}
