import { inject, reactive } from 'vue';
import { AuthStore } from './types';

const state = reactive({
  token: sessionStorage.getItem('jwt_token'),
});

const methods = {
  setToken(token: string): void {
    state.token = token;
    sessionStorage.setItem('jwt_token', token);
  },
  clearToken(): void {
    state.token = null;
    sessionStorage.removeItem('jwt_token');
  },
  initializeAuth(): void {
    const token = sessionStorage.getItem('jwt_token');
    if (token) {
      state.token = token;
    }
  }
};

const getters = {
  isAuthenticated: (): boolean => !!state.token,
  getToken: (): string | null => {
    return state.token ?? sessionStorage.getItem('jwt_token');
  }
};

const authStore: AuthStore = {
  state,
  ...methods,
  ...getters,
};

export const useAuth = (): AuthStore => {
  const auth = inject<AuthStore>('auth');
  if (!auth) {
    throw new Error('No auth store provided');
  }
  return auth;
};

export default authStore;
