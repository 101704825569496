
import { computed, defineComponent, reactive } from 'vue';
import { Head as VueHead } from '@vueuse/head';
// import butter from '@/plugins/butter.plugin';

declare interface Metatag {
  name?: string;
  property?: string;
  alt?: string;
  content?: string;
}

export default defineComponent({
  name: 'AsyncHead',
  components: {
    VueHead
  },
  async setup() {
    const baseUrl = computed(() => process.env.BASE_URL);
    const head = reactive({
      metatags: [] as Array<Metatag>,
      title: 'Speakfully'
    });
    // const butterReq = await butter.page.retrieve('*', 'speakfullynow');
    // head.metatags = butterReq.data.data.fields.meta_tags
    // head.title = butterReq.data.data.fields.page_title
    return {
      head,
      baseUrl
    };
  }
});
