import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main-menu',
    component: () => import(/* webpackChunkName: "main-menu" */ '@/views/MainMenu.vue')
  },
  {
    path: '/document',
    name: 'document-experience',
    component: () => import(/* webpackChunkName: "document-experience" */ '@/views/DocumentExperience.vue')
  },
  {
    path: '/confirm-submission',
    name: 'confirm-submission',
    props: true,
    component: () => import(/* webpackChunkName: "confirm" */ '@/views/ConfirmSubmission.vue')
  },
  {
    path: '/webform-confirm-submission',
    name: 'webform-confirm-submission',
    props: true,
    component: () => import(/* webpackChunkName: "confirm" */ '@/views/WebformConfirmSubmission.vue')
  },
  {
    path: '/submission',
    props: true,
    component: () => import(/* webpackChunkName: "confirm" */ '@/views/ViewSubmission.vue'),
    children: [
        {
            path: '',
            name: 'submission',
            props: {
              main: true
            },
            components: {
                main: () => import(/* webpackChunkName: 'submission' */ '@/components/submission/Experience.vue')
            }
        },
        {
          path: 'messages',
          name: 'messages',
          props: {
            main: true
          },
            components: {
                main: () => import(/* webpackChunkName: 'submission' */ '@/components/submission/Messages.vue')
            }
        }
      ]
  },
  {
    path: '/track/:submissionKey?',
    name: 'track-submission',
    props: true,
    component: () => import(/* webpackChunkName: "confirm" */ '@/views/TrackSubmission.vue')
  }, 
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
