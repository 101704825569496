import { InMemoryCache } from '@apollo/client/core';

const cache = new InMemoryCache({
	addTypename: true,
	typePolicies: {
    Query: {
      fields: {
        submission(_, { args, toReference }) {
          return toReference({
            __typename: 'AnonymousSubmission',
            id: args?.id
          });
        }
      }
    },
		AnonymousSubmission: {
      fields: {
        messages: {
        }
      }
    },
    Message: {
      merge: true
    }
	}
});

export default cache;
