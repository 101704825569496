import { MessageFragment, SubmissionFragment } from "@/graphql/fragments.gql";
import { gql } from "@apollo/client/core";

export const ORG_INFO = gql`
  query orgInfo {
    orgInfo {
      id
      name
      isIssueReportAndTrackSubmissionButton
      isIntegratedOrg
      orgLanguages
      appConfig {
        isPasswordRequired
        logo
        displayName
        showSpeakfullyTermsLink
        submissionFormTitle
        employeeTerms {
          text
          url
        }
      }
      submissionConfig {
        requireExperienceExplanation
        keyTitle
        keySubtitle
        keyNextStepsTitle
        keyNextStepsText
        askDateOfExperience
        askAddInvolvedPeople
        askAddAttachments
        messagingEnabled
        allowIdentityDisclosure
        experienceDateRequired
        experienceDescriptionInstruction
        identityDisclosureInstruction
        allowIdentityDisclosureAndUpdate
        allowAnonymousSubmission
        disableTextMessageUpdate
        personsInvolvedProperties {
          types
          titles
        }
      }
      tagOptions {
        tag
        _id
        category
        isActive
      }
      webForm {
        isWebform
        title
        type
        coreQueueName
      }
      
      homepageConfig {
        welcomeText
        introductionText
        issueReportButtonText
        trackSubmissionButtonText
        
      }
      trackYourSubmissionConfig {
        trackSubmissionTitleText
        trackSubissionSubtitleText
        trackSubissionDetailText
      }
      orderConfig {
        dateOfExperience
        attachment
        experienceDetails
        personsInvolved
        identity
      }
      currentSubmissions {
        folderType
        questions {
          openTextField
          priority
          submissionType
          determinesSubmissionType
          groupLabel
          questionType
          questionMedia
          label
          multi
          instruction
          questionText
          skippable
          multipleChoice
          answerOptions {
            overrideIdentityDisclosure
            answerText
            media
            tag {
              category
              tag
              id
              isActive
            }
            displayTextField
            displayTextFieldLabel
            questions {
              groupLabel
              questionType
              label
              multi
              instruction
              questionText
              skippable
              answerOptions {
                overrideIdentityDisclosure
                displayTextField
                displayTextFieldLabel
                answerText
                media
                tag {
                  category
                  tag
                  id
                  isActive
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const AUTHORIZE_SUBMISSION_ACCESS = gql`
  query authorizeSubmission($submissionAuthData: SubmissionAuthData!) {
    authorizeSubmission(submissionAuthData: $submissionAuthData) {
      ...SubmissionFragment
    }
  }
  ${SubmissionFragment}
`;

export const SUBMISSION = gql`
  query submission($id: String!) {
    submission(id: $id) {
      ...SubmissionFragment
    }
  }
  ${SubmissionFragment}
`;

export const MESSAGES = gql`
  query submission($id: String!) {
    submission(id: $id) {
      messages {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;
