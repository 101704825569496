import {
	ApolloClient,
	HttpLink,
	ApolloLink
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import cache from './graphql/cache.gql';
import auth from './store/auth';

const GRAPHQL_LINK = process.env.VUE_APP_GRAPHQL_LINK || '';

const httpLink = new HttpLink({
  uri: GRAPHQL_LINK
});


const authLink = setContext(async (_, { headers }) => {
  const token = auth.getToken();
  const data = {
    headers: {
      ...headers,
      ...(token?.length && { authorization: `Bearer ${token}` }),
    }
  };
  return data;
});

const link = ApolloLink.from([authLink, httpLink]);

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
// 			definition.kind === 'OperationDefinition'
// 			&& definition.operation === 'subscription'
//     );
//   },
//   //wsLink,
//   link
// );

export default new ApolloClient({
  link,
  cache
});
