import { gql } from '@apollo/client/core';

export const SubmissionFragment = gql`
  fragment SubmissionFragment on AnonymousSubmission {
    jwtSpeakfullyNow
    id
    isIntegratedFolder
    org {
      isIntegratedOrg
    }
    name
    reportedBy
    currentStatusDate
    folderType
    submittedDate
    isMetadata
    personsInvolved {
      name
      type
      title
    }
    statusHistory {
      status
      date
      userId
    }
    updates {
      id
      text
      date
    }
    updateEmail
    updatePhone
    messages {
      id
      userId
      message
      isCoreUser
      createdDate
      modifiedDate
      attachments {
        id
        filepath
        messageId
        filename
        fileUrl
      }
    }
    currentStatus
    actionHistory {
      action
      date
      userId
    }
    isActive
    isPrivate
    incidents {
      id
      description
      isDraft
      dateOfIncident
      statusHistory {
        status
        date
        userId
      }
      attachments {
        id
        filepath
        incidentId
        filename
        fileUrl
        mimetype
      }
      answers {
        questionMedia
        questionLabel
        questionType
        questionGroupLabel
        option
        otherResponse
        textFieldValue
      }
    }
    lastVisitedDate
  }
`;


export const MessageFragment = gql `
  fragment MessageFragment on Message {
    id
    userId
    message
    isCoreUser
    createdDate
    modifiedDate
    attachments {
      id
      filepath
      messageId
      filename
      fileUrl
    }
  }
`;
