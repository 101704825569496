
import { defineComponent, onMounted, ref} from 'vue';
import { useGlobalQueryLoading } from '@vue/apollo-composable';
import { useQuery, useResult } from '@vue/apollo-composable';
import { ORG_INFO } from './graphql/queries.gql';
import { useRoute } from 'vue-router';
import AsyncHead from '@/components/AsyncHead.vue'
import { Organization } from './typedefs/org';
import router from './router';
import InitializePendo from './pendo-tracking';
import LanguageSwitcher from './components/common/LanguageSwitcher.vue';
import getLanguges from './composables/getallLangs';
import { useAuth } from './store/auth';
export default defineComponent({
    name: 'App',
    components: {
      AsyncHead,
      LanguageSwitcher
    },
    setup() {
      const allLangs = ref({ code: [], name: {} });
      const isLoadingLangs = ref(true)
      const orgLanguages =ref();
      const { initializeAuth } = useAuth();
      initializeAuth();

onMounted(async () => {
  try {
    allLangs.value = await getLanguges();
    orgLanguages.value = useResult(result,Object.values(allLangs.value.code), ({ orgInfo }) => (orgInfo as Organization).orgLanguages)
    isLoadingLangs.value =false;
  } catch (error) {
    isLoadingLangs.value =false
    console.error("Failed to load languages:", error);
  }
});
        const loading = useGlobalQueryLoading();
        const route = useRoute();
        const { result, refetch } = useQuery(ORG_INFO);
        const logo = useResult(result, null, ({ orgInfo }) => (orgInfo as Organization).appConfig.logo)
        const displayName = useResult(result, null, ({orgInfo}) => (orgInfo as Organization).appConfig.displayName)
        const isWebform = useResult(result,null, ({ orgInfo }) => (orgInfo as Organization).webForm.isWebform);  
        const orgName = useResult(result, null, ({ orgInfo }) => (orgInfo as Organization).name)   
        const apiKey = process.env.VUE_APP_SN_PENDO_API_KEY;
        const enablePendoTracking  = process.env.VUE_APP_ENABLE_PENDO_TRACKING;
        const environmentName = process.env.VUE_APP_ENVIRONMENT_NAME;

        router.beforeEach(async (to, from, next) => {
          await refetch()
            if(isWebform.value && (to.name === "main-menu" || to.name === "track-submission" || to.name === "confirm-submission")){
                next('/document')
              } else {
                next()
              }       
             enablePendoTracking == 'true' ? 
               InitializePendo(orgName.value ?? "", displayName.value ?? "", apiKey ?? "", environmentName ?? "") 
              : null
           })

        return {
            loading,
            logo,
            route,
            displayName,
            LanguageSwitcher,
            orgLanguages,
            allLangs,
            isLoadingLangs
        };
    }
});
