import { createApp, h, provide } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import ElementPlus from 'element-plus';
import { createHead } from '@vueuse/head';
import SpeakfullyComponentLib from 'speakfully-component-lib';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import '@/plugins/dayjs.plugin';
import "maz-ui/css/main.css";
import "element-plus/dist/index.css";
import "@/styles/global.scss";
import App from '@/App.vue';
import router from './router';
import defaultClient from './apollo-client';
import auth from './store/auth';
// FontAwesome Icon Library - import as needed to lib file
import '@/lib/icons';

const headObj = createHead();

createApp(
	{
		setup() {
      provide(DefaultApolloClient, defaultClient);
		},
		render() {
			return h(App);
		}
	}
)
.use(headObj)
.use(router)
.use(ElementPlus)
.use(SpeakfullyComponentLib)
.component('fa', FontAwesomeIcon)
.component('fa-layers', FontAwesomeLayers)
.component('fa-layers-text', FontAwesomeLayersText)
.provide('auth', auth)
.mount('#app');

// @ts-expect-error: Append head to window
window.head = headObj
